import React, { useEffect } from 'react'
const pkg = require('react-helmet-async')
const { Helmet, HelmetProvider } = pkg
import { Provider } from 'react-redux'
import { PageContextProvider } from "./usePageContext"
import { AppEnv } from "../config/AppConfig"
import { envURL } from "../lib/urlHelpers"
import { Tolgee, useTolgeeSSR, DevTools, TolgeeProvider, FormatSimple, BackendFetch } from "@tolgee/react"
import {
  initializeFaro,
  ReactIntegration,
  getWebInstrumentations,
  FaroErrorBoundary,
  FetchTransport,
  LogLevel
} from '@grafana/faro-react'

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .use(BackendFetch())
  .init({
    defaultLanguage: 'en',
    availableLanguages: ['en', 'fi', 'uk'],

    // for development
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY
  });


function App({ store, pageContext, helmetContext, tolgeeStaticData }) {
  const { Page, routeParams, urlParsed, tenant, langCode, staticFields, cacheVersion, traceId } = pageContext
  const canonicalUrl = envURL(tenant, urlParsed.pathname)
  const tolgeeSSR = useTolgeeSSR(tolgee, langCode, tolgeeStaticData)
  console.log('Language: ', langCode)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const faro = initializeFaro({
        url: 'https://o.letterty.com/collect',
        apiKey: import.meta.env.PUBLIC_ENV__FARO_API_KEY,
        app: {
          name: 'letterty-frontend',
          version: cacheVersion,
          environment: AppEnv
        },
        instrumentations: getWebInstrumentations({ captureConsole: true })
      })

      window.addEventListener("error", (errorEvent) => {
        console.log("Error: ", errorEvent)
        faro.api.pushLog([errorEvent.message], {
          level: LogLevel.ERROR,
          context: {
            error_type: 'exception'
          }
        })
      })

      window.addEventListener("unhandledrejection", (errorEvent: PromiseRejectionEvent) => {
        console.log("Promise Error: ", errorEvent)
        faro.api.pushLog([errorEvent.reason?.message], {
          level: LogLevel.ERROR,
          context: {
            error_type: 'promiseException'
          }
        })
      })
    }
  }, [])

  return(
    <React.StrictMode>
      <FaroErrorBoundary>
        <PageContextProvider pageContext={pageContext}>
          <Provider store={store}>
              <HelmetProvider context={helmetContext}>
                <Helmet>
                  <title>{staticFields.title}</title>
                  <meta name="description" content={staticFields.description} />
                  <meta property="og:description" content={staticFields.description} />
                  <meta property="og:url" content={canonicalUrl} />
                  <link rel="canonical" href={canonicalUrl} />
                </Helmet>
                <TolgeeProvider tolgee={tolgeeSSR} fallback={"..."} forceLanguage={langCode} enableLanguageDetection={false} >
                  <Page pageContext={pageContext} routeParams={routeParams} />
                </TolgeeProvider>
              </HelmetProvider>
          </Provider>
        </PageContextProvider>
      </FaroErrorBoundary>
    </React.StrictMode>
  )
}

export default App
