import { useAppSelector } from "../hooks"
import {
  selectPoliciesLoaded,
  selectPolicyFor,
  selectFeatureOptionVariant,
  selectIsMember,
  selectOrgs,
  selectOrgsFirst,
  selectOrgsFirstSubdomain
} from "../renderer/policiesSlice"
import { usePageContext } from "../renderer/usePageContext"

function useCurrentMemberOrg() {
  return useAppSelector(selectOrgsFirst)
}

function useOrgsFirstSubdomain() {
  return useAppSelector(selectOrgsFirstSubdomain)
}

function useMemberOrgs() {
  return useAppSelector(selectOrgs)
}

function useIsMember() {
  return useAppSelector(selectIsMember)
}

function isPublicOrg(pageContext) {
  return(!pageContext.tenant || pageContext.tenant.length === 2)
}

function useIsPublicOrg() {
  const pageContext = usePageContext()

  return isPublicOrg(pageContext)
}

function usePoliciesLoaded() {
  return useAppSelector((state) => selectPoliciesLoaded(state))
}

function IfPoliciesLoaded({ forceYes , elseComponent, children }) {
  if (forceYes || usePoliciesLoaded()) {
    return(<>{ children }</>)
  } else {
    return(elseComponent)
  }
}

function useIsAllowed({ klass, action }) {
  return useAppSelector((state) => selectPolicyFor(state, klass, action))
}

function IfAllowed({ klass, action, children }) {
  const isAllowed = useIsAllowed({ klass, action })

  if (isAllowed) {
    return(<>{ children }</>)
  } else {
    return null
  }
}

function useFeatureOption({ feature }) {
  return useAppSelector((state) => selectFeatureOptionVariant(state, feature))
}

function FeatureOption({ feature, optionVariant, children }) {
  const featureOptionVariant = useFeatureOption({ feature })

  if (optionVariant === featureOptionVariant) {
    return(<>{ children }</>)
  } else {
    return null
  }
}

export {
  useCurrentMemberOrg,
  useOrgsFirstSubdomain,
  useMemberOrgs,
  useIsMember,
  isPublicOrg,
  useIsPublicOrg,
  IfPoliciesLoaded,
  usePoliciesLoaded,
  useIsAllowed,
  IfAllowed,
  useFeatureOption,
  FeatureOption
}
